import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import { useConnectModalStore } from '../../stores/useConnectModalStore';
import { ChainId } from '../../helpers/contract-helpers';
import { getNetworkData } from '../../helpers/config/markets-and-network-config';
import toast from 'react-hot-toast';
import { useIntl } from 'react-intl';
import messages from '../web3-data-provider/messages';

function useNetworkSwitcherInSwitchNetworkButton() {
  const { active } = useWeb3React<ethers.providers.Web3Provider>();
  const { isVisible, setIsVisible } = useConnectModalStore((state) => ({
    setIsVisible: state.setIsVisible,
    isVisible: state.isVisible,
  }));
  const intl = useIntl();

  const handleSwitchNetwork = async (network: ChainId) => {
    if (!active && !isVisible) {
      setIsVisible(true);
      return { isSwitched: false };
    }
    try {
      const networkData = getNetworkData(network);
      await window.ethereum?.request({
        method: 'wallet_addEthereumChain',
        params: [networkData],
      });

      const isSwitched = networkData.chainId === window.ethereum.chainId;
      return { isSwitched };
    } catch (switchError) {
      toast(
        switchError.code === -32002
          ? intl.formatMessage(messages.errorOpenWallet)
          : intl.formatMessage(messages.switchNetworkError, { message: switchError.message }),
        {
          duration: 7000,
          position: 'top-center',
          style: { fontSize: '18px', color: '#fff', background: '#aa0000' },
          icon: '⚠️',
          id: '-32002',
        }
      );

      return { isSwitched: false };
    }
  };

  return { handleSwitchNetwork };
}

export default useNetworkSwitcherInSwitchNetworkButton;
