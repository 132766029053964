import React from 'react';
import {
  getAssetInfoFactory,
  TokenIcon as DefaultTokenIcon,
  TokenIconProps,
} from '@omnidex/omnidex-ui-kit';

import aIcons from '../../ui-config/assets-list/aIcons';
import icons from '../../ui-config/assets-list/icons';

import {assetsList, stableAssets } from '../../ui-config/assets';
// import { useWeb3React } from '@web3-react/core';
// const { chainId } = useWeb3React();



export const getAssetInfo = getAssetInfoFactory(assetsList);

export const getAssetColor = (assetSymbol: string) => {
  const asset = getAssetInfo(assetSymbol);
  const assetColor = asset.color;

  return assetColor || '#2ebac6';
};

export const isAssetStable = (assetSymbol: string) => {
  const assetInfo = getAssetInfo(assetSymbol);
  return stableAssets.includes(assetInfo.symbol.toLocaleUpperCase());
};

export const TokenIcon = (props: Omit<TokenIconProps, 'getAssetInfo'>) => (
  <DefaultTokenIcon {...props} getAssetInfo={getAssetInfo} />
);

{/*
  
export interface Asset {
  name: string;
  symbol: string;
  formattedSymbol?: string;
  color?: string;
  icon?: string;
  aIcon?: string;
  symbolFormatted?: string;
  symbolsArray?: string[];
  formattedName?: string;
  shortSymbol?: string;
}


export const assetsList: Asset[] = [

  {
    name: 'Wrapped Telos',
    symbol: 'WTLOS',
    color: '#9151b6',
    icon: icons.wtlos,
    aIcon: aIcons.awtlos,
  },
  {
    name: 'Telos',
    symbol: 'TLOS',
    color: '#9151b6',
    icon: icons.wtlos,
    aIcon: aIcons.awtlos,
  },
  {
    name: 'Wrapped ETH',
    symbol: 'WETH',
    color: '#000000',
    icon: icons.weth,
    aIcon: aIcons.aweth,
  },
  {
    name: 'ETH',
    symbol: 'ETH',
    color: '#000000',
    icon: icons.weth,
    aIcon: aIcons.aweth,
  },
  {
    name: 'USD Coin',
    symbol: 'USDC',
    formattedSymbol: 'USDC',
    color: '#2775ca',
    icon: icons.usdc,
    aIcon: aIcons.ausdc,
  },
  {
    name: 'USDT Coin',
    symbol: 'USDT',
    formattedSymbol: 'USDT',
    color: '#28b171',
    icon: icons.usdt,
    aIcon: aIcons.ausdt,
  },
  {
    name: 'Staked TLOS',
    symbol: 'STLOS',
    formattedSymbol: 'STLOS',
    color: '#9151b6',
    icon: icons.stlos,
    aIcon: aIcons.stlos,
  },
];
  
  */}