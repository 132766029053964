import { create } from 'zustand';

interface ConnectModalState {
  isVisible: boolean;
  setIsVisible: (newState: boolean) => void;
}

export const useConnectModalStore = create<ConnectModalState>((set) => ({
  isVisible: false,
  setIsVisible: (newState) => set({ isVisible: newState }),
}));
