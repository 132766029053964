import { MessageDescriptor } from 'react-intl';
import { moreMenuExtraItems, moreMenuItems, moreMenuMobileOnlyItems } from '../../../ui-config';
import { MarketDataType } from '../../../helpers/config/types';

import messages from './messages';

export interface Navigation {
  link: string;
  title: MessageDescriptor;
  hiddenWithoutWallet?: boolean;
  absolute?: boolean;
  onClick?: () => void;
  children?: Navigation[];
  isVisible?: (data: MarketDataType) => boolean | undefined;
}

const navigation: Navigation[] = []
{/*
  {
    link: '/markets',
    title: messages.lending,
  },

  {
    link: '/dashboard',
    title: messages.dashboard,
  },
  {
    link: 'https://mint.meridianfinance.net/#/', // External link
    title: messages.usdm,
    absolute: true,
  },
  {
    link: 'https://stake.meridianfinance.net/#/', // External link
    title: messages.stake,
    absolute: true,
  },
  {
    link: 'https://bridge.meridianfinance.net/#/', // External link
    title: messages.bridge,
    absolute: true,
  },
];
*/}
export const moreNavigation: Navigation[] = [...moreMenuItems, ...moreMenuExtraItems];

export const mobileNavigation: Navigation[] = [...navigation, ...moreMenuMobileOnlyItems];

export default navigation;
