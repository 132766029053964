import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './PageSwitcher.css'; // Import the CSS file

const PageSwitcher = () => {
  const location = useLocation(); // Get the current location
  const [activePage, setActivePage] = useState(location.pathname); // Initialize activePage state with current path

  const handlePageChange = (path: string) => {
    setActivePage(path); // Update activePage state when a button is clicked
  };

  return (
    <div className="page-switcher-container">
        <div className="page-switcher">
        <Link to="/markets" className={`page-switcher-button ${activePage === '/markets' ? 'active' : ''}`} onClick={() => handlePageChange('/markets')}>
            Markets
        </Link>
        <Link to="/dashboard" className={`page-switcher-button ${activePage === '/dashboard' ? 'active' : ''}`} onClick={() => handlePageChange('/dashboard')}>
            Dashboard
        </Link>
        <Link to="/deposit" className={`page-switcher-button ${activePage === '/deposit' ? 'active' : ''}`} onClick={() => handlePageChange('/deposit')}>
            Deposit
        </Link>
        <Link to="/borrow" className={`page-switcher-button ${activePage === '/borrow' ? 'active' : ''}`} onClick={() => handlePageChange('/borrow')}>
            Borrow
        </Link>
        </div>
    </div>
  );
};

export default PageSwitcher;

