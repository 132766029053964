import { ChainId } from '@aave/protocol-js';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useIntl } from 'react-intl';
import { NETWORK_ID_MAP } from '../../ui-config/networks';
import messages from '../web3-data-provider/messages';
import { CustomMarket, marketsData } from '../../helpers/config/markets-and-network-config';
import { useCurrentMarketStore } from '../../stores/useCurrentMarketStore';
import { useConnectModalStore } from '../../stores/useConnectModalStore';

// This hook is to sync current network when the website initially loads and listen to chain changed.
function useNetworkSync({ callback }: { callback: (networkId: number) => void }) {
  const { active, library } = useWeb3React<ethers.providers.Web3Provider>();
  const intl = useIntl();
  const setCurrentMarket = useCurrentMarketStore((state) => state.setCurrentMarket);
  const setSelectWalletModalVisible = useConnectModalStore((state) => state.setIsVisible);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on('chainChanged', (chainId: string) => {
        console.log('chainChanged', { chainId });
        const networkIdStr = Object.keys(NETWORK_ID_MAP).find(
          (k) => NETWORK_ID_MAP[k as keyof typeof NETWORK_ID_MAP].chainId === chainId
        ) as ChainId | undefined;

        if (networkIdStr) {
          const networkId = Number(networkIdStr);
          const key = Object.keys(marketsData).find(
            (k) => marketsData[k]?.chainId === networkId
          ) as CustomMarket | undefined;

          if (key) {
            setCurrentMarket(key);
            callback(networkId);
          }
        } else {
          toast(intl.formatMessage(messages.errorTitle), {
            duration: 7000,
            position: 'top-center',
            style: { fontSize: '18px', color: '#fff', background: '#aa0000' },
            icon: '⚠️',
            id: 'UnsupportedChainIdError',
          });
          setSelectWalletModalVisible(true);
        }
      });
    }
  }, [window.ethereum]);

  useEffect(() => {
    if (active) {
      library?.getNetwork().then(({ chainId }) => {
        console.log('getNetwork', chainId);
        const networkIdStr = Object.keys(NETWORK_ID_MAP).find((k) => k === `${chainId}`) as
          | ChainId
          | undefined;

        if (networkIdStr) {
          const networkId = Number(networkIdStr);
          const key = Object.keys(marketsData).find(
            (k) => marketsData[k]?.chainId === networkId
          ) as CustomMarket | undefined;

          if (key) {
            setCurrentMarket(key);
            console.log({ key });
            callback(networkId);
          }
        }
      });
    }
  }, [active]);

  return null;
}

export default useNetworkSync;
