import React from 'react';
import { useIntl } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import { rgba, useThemeContext } from '@omnidex/omnidex-ui-kit';
import classNames from 'classnames';

import staticStyles from './style';
import { useCurrentMarketStore } from '../../../stores/useCurrentMarketStore';
import taiko from '../../../images/taiko.png';

interface LiquidityMiningAPYLineProps {
  symbol?: string;
  value: string | number;
  tooltipId?: string;
}

export default function MaxPointsMiningCard({
  symbol,
  value,
  tooltipId,
}: LiquidityMiningAPYLineProps) {
  const { currentTheme, xl } = useThemeContext();
  const isCurrentThemeDark = false;

  const { networkConfig } = useCurrentMarketStore((state) => ({
    networkConfig: state.networkConfig,
  }));

  const borderColor = rgba(`${currentTheme.lightBlue.rgb}, 0.2`);

  return (
    <div
      className={classNames('LiquidityMiningAPYLine', {
        LiquidityMiningAPYLine__withTooltip: tooltipId,
      })}
      data-tip={true}
      data-for={tooltipId}
      style={{ backgroundColor: "#b0d6cb", display: 'flex', alignItems: 'center' }} // Add background color and flex alignment here
    >
      <img src={taiko} alt="Taiko" style={{ marginRight: '6px', marginTop: '2px', marginBottom: '2px', height: '15px' }} /> {/* Add image here */}
      <p className="LiquidityMiningAPYLine__title">4x TrailBlazer XP</p>

      {!!tooltipId && (
        <ReactTooltip className="LiquidityMiningAPYLine__tooltip" id={tooltipId} effect="solid">
          <div className="LiquidityMiningAPYLine__tooltip--content">
            <p>
              {`Earn Taiko TrailBlazer XP`}
            </p>
          </div>
        </ReactTooltip>
      )}

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        .LiquidityMiningAPYLine {
          color: ${currentTheme.lightBlue.hex};
          border: 1px solid ${borderColor};

          &__title {
            font-size: 12px; /* Decrease the text size */
            font-weight: bold;
            color: black; /* Change text color to black */
          }

          .LiquidityMiningAPYLine__tooltip {
            background: ${isCurrentThemeDark
          ? currentTheme.mainBg.hex
          : currentTheme.primary.hex} !important;
            &:after {
              border-top-color: ${isCurrentThemeDark
          ? currentTheme.mainBg.hex
          : currentTheme.darkBlue.hex} !important;
            }
          }
        }
      `}</style>
    </div>
  );
}
