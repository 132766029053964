import { MarketDataType } from '../../helpers/config/types';

import * as logos from './images';

export enum CustomMarket {
  // proto_telos = 'proto_telos',
  // proto_neon_testnet = 'proto_neon_testnet',

  proto_meter_mainnet = 'proto_meter_mainnet',
  proto_telos_mainnet = 'proto_telos_mainnet',
  proto_fuse_mainnet = 'proto_fuse_mainnet',
  proto_taiko_mainnet = 'proto_taiko_mainnet'

}

export const marketsData: { [key in keyof typeof CustomMarket]: MarketDataType } = {
  [CustomMarket.proto_telos_mainnet]: {
    chainId: 40,
    logo: logos.telosLogo,
    activeLogo: logos.telosActiveLogo,
    aTokenPrefix: 'O',
    enabledFeatures: {
      faucet: false,
      governance: false,
      staking: false,
      incentives: false,
    },
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: '0x703cF2C85EA76C54bd863337585673B3DF8FCE72'.toLowerCase(),
      LENDING_POOL: '0xd8d02083570f457f96864CEb1720E368B5C9Fe51'.toLowerCase(),
      WETH_GATEWAY: '0x6554a224dbaCE1d137DB6c8c2F0DD000923C26b1'.toLowerCase(),
      FAUCET: '',
    },
  },
  [CustomMarket.proto_meter_mainnet]: {
    chainId: 82,
    logo: logos.meterLogo,
    activeLogo: logos.meterLogo,
    aTokenPrefix: 'O',
    enabledFeatures: {
      faucet: false,
      governance: false,
      staking: false,
      incentives: false,
    },
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: '0xB1592dBb47A5Cbd2540197Fd552B1d985121c643'.toLowerCase(),
      LENDING_POOL: '0xDcA551F04EfA24D7B850D7D6B35F6767b950C840'.toLowerCase(),
      WETH_GATEWAY: '0x53aC17B12a0EeaFfdEc15D67B3AE0D216e464888'.toLowerCase(),
      FAUCET: '',
    },
  },
  [CustomMarket.proto_taiko_mainnet]: {
    chainId: 167000,
    logo: logos.taikoLogo,
    activeLogo: logos.taikoLogo,
    aTokenPrefix: 'O',
    enabledFeatures: {
      faucet: false,
      governance: false,
      staking: false,
      incentives: false,
    },
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: '0x9Fe3659aFf8CDaf4cd009ef7a63DaB23b3CA15C7'.toLowerCase(),
      LENDING_POOL: '0x1697A950a67d9040464287b88fCa6cb5FbEC09BA'.toLowerCase(),
      WETH_GATEWAY: '0x00A332879be62E2CaD5f57DfcB0768BD00d56D2A'.toLowerCase(),
      FAUCET: '',
    },
  },
  [CustomMarket.proto_fuse_mainnet]: {
    chainId: 122,
    logo: logos.fuseLogo,
    activeLogo: logos.fuseLogo,
    aTokenPrefix: 'O',
    enabledFeatures: {
      faucet: false,
      governance: false,
      staking: false,
      incentives: false,
    },
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: '0x89bB8A7F3B031723D580ef9D1800fb7CF6c8870d'.toLowerCase(),
      LENDING_POOL: '0x08E387E24E3b431790E845D1b3c02913679A8b2F'.toLowerCase(),
      WETH_GATEWAY: '0x5507dc1Dde4245F7BEb2E9B0C37Fc3119A327eE1'.toLowerCase(),
      FAUCET: '',
    },
  },
} as const;

{
  /*
  [CustomMarket.proto_neon_testnet]: {
    chainId: 245022926,
    logo: logos.neonLogo,
    activeLogo: logos.neonLogo,
    aTokenPrefix: 'O',
    enabledFeatures: {
      faucet: false,
      governance: false,
      staking: false,
      incentives: false,
    },
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: '0x534bC485f111B7C8D361F2C9e24076BBE4AA0D36'.toLowerCase(),
      LENDING_POOL: '0x70A161B43fa92742bd4D670116b7d7b860e99478'.toLowerCase(),
      WETH_GATEWAY: '0x9376DB040fE7Da43866471036c06E00bBA0Ce8EF'.toLowerCase(),
      FAUCET: '',
    },
  },
*/
}
