import { BaseNetworkConfig } from '../helpers/config/types';
import { ChainId } from '../helpers/contract-helpers';

export const NETWORK_ID_MAP: Record<string, { chainId: `0x${string}`; symbol: string }> = {
  [ChainId.testnet]: { chainId: '0x29', symbol: 'TLOS' },
  [ChainId.neon_testnet]: { chainId: '0xe9ac0ce', symbol: 'NEON' },
  [ChainId.mainnet]: { chainId: '0x28', symbol: 'TLOS' },
  [ChainId.fuse]: { chainId: '0x7a', symbol: 'FUSE' },
  [ChainId.meter]: { chainId: '0x52', symbol: 'MTR' },
  [ChainId.taiko]: { chainId: '0x28c58', symbol: 'ETH' },
  [ChainId.base]: { chainId: '0x2105', symbol: 'ETH' },
};

export const networkConfigs: Record<string, BaseNetworkConfig> = {
  [ChainId.testnet]: {
    name: 'Telos EVM Testnet',
    publicJsonRPCUrl: ['https://testnet.telos.net/evm'],
    addresses: {
      walletBalanceProvider: '0xAEF6723254dC1aE043C23d60727C89B2950Cddac',
      uiPoolDataProvider: '0x918C14931e4Bc3AE407D859d6585cC79A1A5d0B8', // '0x3c4a38019e6EE5158d3c009B22f566Ae1A5Eb4ed',
      uiIncentiveDataProvider: '0x20Cc2CC94A6826C6547aDC6e54566d4e235Be6a5', // currently mainnet address
      chainlinkFeedRegistry: '',
    },
    protocolDataUrl: 'https://46.101.109.199:8000/subgraphs/name/omnidex-lending/protocol-v2',
    baseUniswapAdapter: '',
    baseAsset: 'TLOS',
    baseAssetWrappedAddress: '0x019d19d90D030700CF10CbCc3C9eF0ddb28403D7',
    // incentives hardcoded information
    rewardTokenSymbol: 'WTLOS',
    rewardTokenAddress: '0x019d19d90D030700CF10CbCc3C9eF0ddb28403D7',
    rewardTokenDecimals: 18,
    explorerLink: 'https://testnet.teloscan.io',
    rpcOnly: true,
    isTestnet: false,
  },
  [ChainId.neon_testnet]: {
    name: 'Neon EVM Testnet',
    publicJsonRPCUrl: ['https://devnet.neonevm.org'],
    addresses: {
      walletBalanceProvider: '0xdA2D93f8c18657E7C9Ff57907322881C6618A7e3',
      uiPoolDataProvider: '0x9b313fC3949f6235ddB58Ff463BdddBc6D2B3DF2', // '0x3c4a38019e6EE5158d3c009B22f566Ae1A5Eb4ed',
      uiIncentiveDataProvider: '', // currently mainnet address
      chainlinkFeedRegistry: '',
    },
    protocolDataUrl: 'https://46.101.109.199:8000/subgraphs/name/omnidex-lending/protocol-v2',
    baseUniswapAdapter: '',
    baseAsset: 'NEON',
    baseAssetWrappedAddress: '0x11adc2d986e334137b9ad0a0f290771f31e9517f',
    // incentives hardcoded information
    rewardTokenSymbol: '',
    rewardTokenAddress: '',
    rewardTokenDecimals: 18,
    explorerLink: 'https://devnet.neonscan.org',
    rpcOnly: true,
    isTestnet: false,
  },
  [ChainId.mainnet]: {
    name: 'Telos EVM Mainnet',
    publicJsonRPCUrl: ['https://mainnet15.telos.net/evm', 'https://mainnet-asia.telos.net/evm	', 'https://rpc3.us.telos.net/evm	', 'https://telos.drpc.org'],
    addresses: {
      walletBalanceProvider: '0xD1A006172f14d8C117B5Cf1ED1197dC18cCe66A3',
      uiPoolDataProvider: '0x002CbD4cf93337DE8c3FD1aeF38348Dc86BfC513', // '0x3c4a38019e6EE5158d3c009B22f566Ae1A5Eb4ed',
      uiIncentiveDataProvider: '0xdbAb1Ca8C13d8feB7567721D06C0BD394c20D0b4', // currently mainnet address
      chainlinkFeedRegistry: '',
    },
    protocolDataUrl: 'https://46.101.109.199:8000/subgraphs/name/omnidex-lending/protocol-v2',
    baseUniswapAdapter: '',
    baseAsset: 'TLOS',
    baseAssetWrappedAddress: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
    // incentives hardcoded information
    rewardTokenSymbol: 'USDM',
    rewardTokenAddress: '0x8f7D64ea96D729EF24a0F30b4526D47b80d877B9',
    rewardTokenDecimals: 18,
    explorerLink: 'https://www.teloscan.io',
    rpcOnly: true,
    isTestnet: false,
  },
  [ChainId.fuse]: {
    name: 'Fuse Mainnet',
    publicJsonRPCUrl: ['https://rpc.fuse.io'],
    addresses: {
      walletBalanceProvider: '0x8A4EEAd335CBd1A7991d7e5B19Cb2788deccB47A',
      uiPoolDataProvider: '0xAbbb9209eb5E1c90C47051D5882fB05a16ACCe9d',
      uiIncentiveDataProvider: '0x48E412f5fBfFc7B3158197aE30F4d1B8187FD105',
      chainlinkFeedRegistry: '',
    },
    protocolDataUrl: 'https://46.101.109.199:8000/subgraphs/name/omnidex-lending/protocol-v2',
    baseUniswapAdapter: '',
    baseAsset: 'FUSE',
    baseAssetWrappedAddress: '0x0BE9e53fd7EDaC9F859882AfdDa116645287C629',
    // incentives hardcoded information
    rewardTokenSymbol: 'WFUSE',
    rewardTokenAddress: '0x0BE9e53fd7EDaC9F859882AfdDa116645287C629',
    rewardTokenDecimals: 18,
    explorerLink: 'https://explorer.fuse.io',
    rpcOnly: true,
    isTestnet: false,
  },
  [ChainId.meter]: {
    name: 'Meter Mainnet',
    publicJsonRPCUrl: ['https://meter.blockpi.network/v1/rpc/public', 'https://rpc.meter.io'],
    addresses: {
      walletBalanceProvider: '0x81d12ec5f1553cC2D9bEECda4B38015a8424C568',
      uiPoolDataProvider: '0xD7142469D3e155c26fA6527b5d8aFbd89Ca43eDe', // '0x3c4a38019e6EE5158d3c009B22f566Ae1A5Eb4ed',
      uiIncentiveDataProvider: '0x37B18D5381387a30B60f06D51552642664f139E8', // currently mainnet address
      chainlinkFeedRegistry: '',
    },
    protocolDataUrl: 'https://46.101.109.199:8000/subgraphs/name/omnidex-lending/protocol-v2',
    baseUniswapAdapter: '',
    baseAsset: 'WMTR',
    baseAssetWrappedAddress: '0x160361ce13ec33c993b5cca8f62b6864943eb083',
    // incentives hardcoded information
    rewardTokenSymbol: 'MTRG',
    rewardTokenAddress: '0x228ebbee999c6a7ad74a6130e81b12f9fe237ba3',
    rewardTokenDecimals: 18,
    explorerLink: 'https://scan.meter.io',
    rpcOnly: true,
    isTestnet: false,
  },
  [ChainId.taiko]: {
    name: 'Taiko Mainnet',
    publicJsonRPCUrl: ['https://rpc.taiko.xyz', 'https://rpc.ankr.com/taiko'],
    addresses: {
      walletBalanceProvider: '0x93335200cdbb8c567A205606D7319CC321D085d0',
      uiPoolDataProvider: '0xF0E8545d106D548c8688795dD9548E0312342018', // '0x3c4a38019e6EE5158d3c009B22f566Ae1A5Eb4ed',
      uiIncentiveDataProvider: '0x78e9c31547C703304D8aF096Ba59C74f5D293AA6', // currently mainnet address
      chainlinkFeedRegistry: '',
    },
    protocolDataUrl: 'https://46.101.109.199:8000/subgraphs/name/omnidex-lending/protocol-v2',
    baseUniswapAdapter: '',
    baseAsset: 'ETH',
    baseAssetWrappedAddress: '0xA51894664A773981C6C112C43ce576f315d5b1B6',
    // incentives hardcoded information
    rewardTokenSymbol: 'TAIKO',
    rewardTokenAddress: '0xA9d23408b9bA935c230493c40C73824Df71A0975',
    rewardTokenDecimals: 18,
    explorerLink: 'https://taikoscan.io/',
    rpcOnly: true,
    isTestnet: false,
  },
  [ChainId.base]: {
    name: 'Base Mainnet',
    publicJsonRPCUrl: ['https://mainnet.base.org/'],
    addresses: {
      walletBalanceProvider: '0x81d12ec5f1553cC2D9bEECda4B38015a8424C568', // DUMMY until Base deployed
      uiPoolDataProvider: '0xD7142469D3e155c26fA6527b5d8aFbd89Ca43eDe', // DUMMY until Base deployed
      uiIncentiveDataProvider: '0x37B18D5381387a30B60f06D51552642664f139E8', // DUMMY until Base deployed
      chainlinkFeedRegistry: '',
    },
    protocolDataUrl: 'https://46.101.109.199:8000/subgraphs/name/omnidex-lending/protocol-v2',
    baseUniswapAdapter: '',
    baseAsset: 'ETH',
    baseAssetWrappedAddress: '0x4200000000000000000000000000000000000006',
    // incentives hardcoded information
    rewardTokenSymbol: 'WETH',
    rewardTokenAddress: '0x4200000000000000000000000000000000000006',
    rewardTokenDecimals: 18,
    explorerLink: 'https://basescan.org',
    rpcOnly: true,
    isTestnet: false,
  },
} as const;

