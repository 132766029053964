import { create } from 'zustand';
import {
  availableMarkets,
  CustomMarket,
  getNetworkConfig,
  getProvider,
  marketsData,
} from '../helpers/config/markets-and-network-config';
import { LS_KEY } from '../libs/token-data-provider';
import { providers } from 'ethers';
import { MarketDataType, NetworkConfig } from '../helpers/config/types';

interface CurrentMarketState {
  currentMarket: CustomMarket;
  setCurrentMarket: (market: CustomMarket) => void;
  currentMarketData: MarketDataType;
  chainId: number;
  networkConfig: NetworkConfig;
  jsonRpcProvider: providers.Provider;
}

/**
 * @returns the last accessed market if it's still available, the first market if not.
 */
const getInitialMarket = () => {
  const cachedMarket = localStorage.getItem(LS_KEY) as CustomMarket | undefined;
  if (cachedMarket && availableMarkets.includes(cachedMarket)) return cachedMarket;
  return availableMarkets[0];
};



export const useCurrentMarketStore = create<CurrentMarketState>((set) => ({
  currentMarketData: marketsData[getInitialMarket()],
  chainId: marketsData[getInitialMarket()].chainId,
  networkConfig: getNetworkConfig(marketsData[getInitialMarket()].chainId),
  jsonRpcProvider: getProvider(marketsData[getInitialMarket()].chainId),
  currentMarket: getInitialMarket(),
  setCurrentMarket: (market: CustomMarket) => {
    set(() => {
      localStorage.setItem(LS_KEY, market);
      const currentMarketData = marketsData[market];
      const { chainId } = currentMarketData;
      const networkConfig = getNetworkConfig(chainId);
      const jsonRpcProvider = getProvider(chainId);
      return { currentMarket: market, currentMarketData, chainId, networkConfig, jsonRpcProvider };
    });
  },
}));
