import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .ScreensWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    overflow: hidden;
    

    &__content {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding-bottom: 40px;
      @include respond-to(sm) {
        padding-bottom: 0px;
      }
      overflow: auto;
      overflow-x: hidden;
      position: relative;
      z-index: 2;
    }

    &__top-contentWrapper {
      position: relative;
      @include respond-to(sm) {
        display: none;
      }
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 110px;
        width: 100%;
        transition-property: height;
        transition-duration: 0.1s;
        transition-timing-function: ease-in-out;
      }
    }

    &__topPanelSmall {
      .ScreensWrapper__top-contentWrapper {
        &:after {
          height: 80px;
        }
      }
    }

    &__background {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      object-fit: cover;

      @include respond-to(sm) {
        display: none;
      }
    }
  }

  .maincontainer {
    display: flex;
    flex-direction: row;
  }

  .left-column {
    z-index: 10;
    width: 60px;
    flex-shrink: 0; /* Prevent the left column from shrinking */
    position: relative; /* Enable absolute positioning for children */
  }

  .right-column {
    z-index: 9;
    flex-grow: 1; /* Allow the right column to grow and fill remaining space */
    position: relative; /* Enable absolute positioning for children */
    padding-left:20px;
  }

  @media screen and (min-width: 768px) {
    .maincontainer {
      flex-wrap: nowrap; /* Prevent columns from wrapping */
    }
  
    .right-column {
      margin-left: 60px; /* Add margin to right column to prevent overlap */
    }
  }

  @media screen and (max-width: 767px) {
    .maincontainer {
      flex-direction: column;
    }
    .right-column {
      margin-top: 10px;
      padding-left: 0;
      margin-left: 0;
    }
  }
`;

export default staticStyles;