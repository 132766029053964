import { IconType } from "react-icons";
import { FaRegChartBar, FaCoins, FaHandshake, FaChartLine, FaArchway, FaSeedling, FaDollarSign, FaArrowAltCircleUp, FaBookReader, FaEllipsisH, FaArrowsAltH, FaBalanceScale } from "react-icons/fa";
import { CustomMarket, marketsData as _marketsData, } from '../../ui-config/markets/index';
import { NETWORK_ID_MAP } from '../../ui-config/networks';
import whiteLinkIcon from '../../images/whiteLinkIcon.svg';


export const BASE_MAINNET = 8453;
export const FUSE_MAINNET = 122;
export const METER_MAINNET = 82;
export const TELOS_MAINNET = 40;
export const TELOS_TESTNET = 41;
export const TAIKO_MAINNET = 167000;

// used to convert chainId number to custom market key definition in lending
export function getCustomMarketKey(chainId: string): CustomMarket {
  for (const marketKey in NETWORK_ID_MAP) {
    if (NETWORK_ID_MAP[marketKey as keyof typeof CustomMarket].chainId === `0x${chainId}`) {
      return marketKey as CustomMarket;
    }
  }
  return "null" as CustomMarket;
}

export interface InAppPath {
  name: string;
  path: string;
}

export interface SubMenuItemConfig {
  networkId: number;
  name: string;
  path: string;
  subSubMenu?: InAppPath[]
}

export interface MenuItemConfig {
  icon: IconType;
  title: string;
  path: string;
  subMenu?: SubMenuItemConfig[];
}

const menuConfig: MenuItemConfig[] = [
  {
    icon: FaCoins,
    title: "Mint USDM",
    path: "https://mint.meridianfinance.net/",
    subMenu: [
      {
        networkId: BASE_MAINNET, name: "Base", path: "https://mint.meridianfinance.net/",
        subSubMenu: [
          { name: "Trove", path: "https://mint.meridianfinance.net/" },
          { name: "Stability Pool", path: "https://mint.meridianfinance.net/#/pool" },
          { name: "Staking", path: "https://mint.meridianfinance.net/#/staking" },
          { name: "Risky Troves", path: "https://mint.meridianfinance.net/#/risky-troves" },
          { name: "Redemptions", path: "https://mint.meridianfinance.net/#/redemption" },
        ]
      },
      {
        networkId: FUSE_MAINNET, name: "Fuse", path: "https://mint.meridianfinance.net/",
        subSubMenu: [
          { name: "Trove", path: "https://mint.meridianfinance.net/" },
          { name: "Stability Pool", path: "https://mint.meridianfinance.net/#/pool" },
          { name: "Staking", path: "https://mint.meridianfinance.net/#/staking" },
          { name: "Risky Troves", path: "https://mint.meridianfinance.net/#/risky-troves" },
          { name: "Redemptions", path: "https://mint.meridianfinance.net/#/redemption" },
        ]
      },
      {
        networkId: TELOS_MAINNET, name: "Telos", path: "https://mint.meridianfinance.net/",
        subSubMenu: [
          { name: "Trove", path: "https://mint.meridianfinance.net/" },
          { name: "Stability Pool", path: "https://mint.meridianfinance.net/#/pool" },
          { name: "Staking", path: "https://mint.meridianfinance.net/#/staking" },
          { name: "Risky Troves", path: "https://mint.meridianfinance.net/#/risky-troves" },
          { name: "Redemptions", path: "https://mint.meridianfinance.net/#/redemption" },
        ]
      },
    ]
  },
  {
    icon: FaHandshake,
    title: "Lend/Borrow",
    path: "https://lend.meridianfinance.net/",
    subMenu: [
      { networkId: FUSE_MAINNET, name: "Fuse", path: "https://lend.meridianfinance.net/" },
      { networkId: METER_MAINNET, name: "Meter", path: "https://lend.meridianfinance.net/" },
      { networkId: TELOS_MAINNET, name: "Telos", path: "https://lend.meridianfinance.net/" },
      { networkId: TAIKO_MAINNET, name: "Taiko", path: "https://lend.meridianfinance.net/" },
    ]
  },
  {
    icon: FaChartLine,
    title: "Trade",
    path: "https://trade.meridianfinance.net/#/trade",
    subMenu: [
      { networkId: BASE_MAINNET, name: "Base", path: "https://trade.meridianfinance.net/#/trade" },
      { networkId: METER_MAINNET, name: "Meter", path: "https://trade.meridianfinance.net/#/trade" },

    ]
  },
  {
    icon: FaArrowsAltH,
    title: "Swap",
    path: "https://swaps.meridianfinance.net",
    subMenu: [
      { networkId: TELOS_MAINNET, name: "Telos", path: "https://swaps.meridianfinance.net" },
    ]
  },
  {
    icon: FaSeedling,
    title: "Stake MST",

    path: "https://stake.meridianfinance.net/#/stakemst",
    subMenu: [
      { networkId: BASE_MAINNET, name: "Base", path: "https://stake.meridianfinance.net/#/stakemst" },
      { networkId: FUSE_MAINNET, name: "Fuse", path: "https://stake.meridianfinance.net/#/stakemst" },
      { networkId: METER_MAINNET, name: "Meter", path: "https://stake.meridianfinance.net/#/stakemst" },
      { networkId: TELOS_MAINNET, name: "Telos", path: "https://stake.meridianfinance.net/#/stakemst" },
    ]
  },
  {
    icon: FaArchway,
    title: "Bridge MST",
    path: "https://bridge.meridianfinance.net/#/",
  },
  {
    icon: FaArrowAltCircleUp,
    title: "Buy MST",
    path: "https://stake.meridianfinance.net/#/tokens/",
  },
  {
    icon: FaDollarSign,
    title: "Buy USDM",
    path: "https://stake.meridianfinance.net/#/tokens/",
  },
  {
    icon: FaRegChartBar,
    title: "Analytics",
    path: "https://stake.meridianfinance.net/#/yields",
  },
  {
    icon: FaEllipsisH,
    title: "More",
    path: "",
    subMenu: [
      { networkId: 0, name: "Tokens", path: "https://stake.meridianfinance.net/#/tokens/" },
      { networkId: 0, name: "Ecosystem", path: "https://stake.meridianfinance.net/#/ecosystem/" },
      { networkId: 0, name: "Docs", path: "https://docs.meridianfinance.net/" },
      { networkId: 0, name: "Github", path: "https://github.com/MeridianDollar" },
      { networkId: 0, name: "Governance", path: "https://snapshot.org/#/meridian-foundation.eth" },
      {
        networkId: 0, name: "Tools", path: "",
        subSubMenu: [
          { name: "Gas Top-up", path: "https://www.gas.zip/" },
          { name: "Base Explorer", path: "https://basescan.org/" },
          { name: "Base Bridge", path: "https://bridge.base.org/" },
          { name: "Fuse Explorer", path: "https://explorer.fuse.io/" },
          { name: "Fuse Bridge", path: "https://console.fuse.io/bridge" },
          { name: "Meter Explorer", path: "https://scan.meter.io/" },
          { name: "Meter Bridge", path: "https://passport.meter.io/" },
          { name: "Telos Explorer", path: "https://www.teloscan.io/" },
          { name: "Telos Bridge", path: "https://bridge.telos.net/bridge" },
          { name: "Taiko Explorer", path: "https://taikoscan.io" },
          { name: "Taiko Bridge", path: "https://bridge.taiko.xyz" },
        ]
      },
    ]
  },
];

export default menuConfig;
