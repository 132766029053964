import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import './Sidebar.css'; // Import CSS for styling
import menuConfig, { MenuItemConfig, SubMenuItemConfig } from "./SidebarConfig";
import { ChainId } from '../../helpers/contract-helpers';
import useNetworkSwitcherInSwitchNetworkButton from '../../libs/hooks/use-network-switcher'
import { FaBars } from "react-icons/fa";
import { isInvalidPath } from "./invalidPaths"

interface SidebarProps {
    chainId: number;
}

const Sidebar: React.FC<SidebarProps> = (props) => {
    const { chainId } = props;
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [activeSubMenu, setActiveSubMenu] = useState<string | null>(null);
    const [activeSubSubMenu, setActiveSubSubMenu] = useState<string | null>(null);
    const [sidebarVisible, setSidebarVisible] = useState<boolean>(false);
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 600);
    const history = useHistory();
    const overlay = useRef<HTMLDivElement>(null);
    const { handleSwitchNetwork } = useNetworkSwitcherInSwitchNetworkButton();

    useEffect(() => {
        function handleResize() {
            setIsLargeScreen(window.innerWidth > 768);
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (overlay.current && !overlay.current.contains(event.target as Node)) {
                setSidebarVisible(false);
            }
        };
        if (sidebarVisible) {
            document.addEventListener('click', handleOutsideClick);
        }
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [sidebarVisible]);

    const sidebarVisibility = isLargeScreen || sidebarVisible;

    useEffect(() => {
        setIsExpanded(sidebarVisible)
    }, [sidebarVisible]);

    const toggleExpandOpen = () => {
        setIsExpanded(true);
    };
    const toggleExpandClose = () => {
        setIsExpanded(false);
    };

    useEffect(() => {
        // Function to determine initial expand state based on screen size
        const handleResize = () => {
            const isSmallScreen = window.innerWidth < 768; // Define "small screen" as less than 768px
            setIsExpanded(isSmallScreen);
        };
        handleResize();
    }, []);


    const handleItemClick = async (chainId: number, targetChainId: number, path: string, isInAppCall: boolean) => {
        if(!isInvalidPath(targetChainId, path)){
            if (chainId != targetChainId && targetChainId != 0) { // change networks if required
                console.log("Swapping Network")
                let newPath = path
                if (isInAppCall) {
                    const hashPath = window.location.hash;
                    const basePath = hashPath.split('/')[0];
                    newPath = `#${basePath.replace(/^#/, '')}${path.slice(1)}`;
                }
                try {
                    const newChainName = await handleSwitchNetwork(targetChainId as ChainId)
                    // if (newChainName === getChainName(targetChainId)) {
                    window.location.href = path;
                    //   }
                } catch (error) {
                    console.error('Error switching network:', error);
                }
            } else {
                if (targetChainId === 0) {
                    window.open(path, '_blank');
                } else {
                    if (isInAppCall) {
                        console.log("In App Call")
                        history.push(path);
                    } else {
                        console.log("External Call")
                        window.location.href = path
                    }
                }
            }
        }
    };

    const handleMenuItemClick = async (isExpanded: boolean, chainId: number, targetChainId: number, item: MenuItemConfig) => {
        const mediaQuery = window.matchMedia('(max-width: 768px)');
        if (mediaQuery.matches && (item.subMenu || !isExpanded)) { // on small screen open submenus if available
            toggleExpandOpen()
            setActiveSubMenu(activeSubMenu === item.title ? null : item.title);
        } else {
            handleItemClick(chainId, targetChainId, item.path, false)
        }
    };

    const handleArrowClick = (item: MenuItemConfig) => {
        if (item.subMenu) {
            setActiveSubMenu(activeSubMenu === item.title ? null : item.title);
        };
    }

    const handleSubArrowClick = (subItem: SubMenuItemConfig) => {
        if (subItem.subSubMenu) {
            setActiveSubSubMenu(activeSubSubMenu === subItem.name ? null : subItem.name);
        };
    }

    return (
        <>
            <div className="menu-icon-container" onClick={() => setSidebarVisible(true)}>
                <FaBars size="medium" />
            </div>
            <div ref={overlay} style={{ display: sidebarVisibility ? 'block' : 'none' }}>
                <div className={`icon-bar ${isExpanded ? 'expanded' : ''}`} onMouseEnter={toggleExpandOpen} onMouseLeave={toggleExpandClose}>
                    <div className="submenu-container">
                        {menuConfig.map((item, index) => (
                            <div key={index} className="menu-item-container">
                                <div className="icon-container">
                                    <div className="sidebar-menu-item" >
                                        <item.icon className="icon" onClick={() => handleMenuItemClick(isExpanded, chainId, chainId, item)} />
                                        <span className="menu-title" onClick={() => handleMenuItemClick(isExpanded, chainId, chainId, item)}>{item.title}</span>
                                        {isExpanded && item.subMenu && (
                                            <span className="arrow" onClick={() => handleArrowClick(item)}>
                                                {activeSubMenu === item.title ? "▼" : "▶"}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                {isExpanded && activeSubMenu === item.title && item.subMenu && (
                                    <div className="sub-menu">
                                        {item.subMenu.map((subItem, subIndex) => (
                                            <div key={subIndex} className="sub-menu-item">
                                                <div className="submenu-title-container">
                                                    <span className="submenu-title" onClick={() => handleItemClick(chainId, subItem.networkId, subItem.path, false)}>
                                                        {subItem.name}
                                                    </span>

                                                    {subItem.subSubMenu && (
                                                        <span className="submenu-arrow" onClick={() => handleSubArrowClick(subItem)}>
                                                            {activeSubSubMenu === subItem.name ? "▼" : "▶"}
                                                        </span>
                                                    )}
                                                </div>
                                                {activeSubSubMenu === subItem.name && subItem.subSubMenu && (
                                                    <div className="sub-sub-menu">
                                                        {subItem.subSubMenu.map((subSubItem, subSubIndex) => (
                                                            <span key={subSubIndex} className="sub-submenu-title" onClick={() => handleItemClick(chainId, subItem.networkId, subSubItem.path, false)}>
                                                                {subSubItem.name}
                                                            </span>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Sidebar;

